import React from "react"
import PropTypes from "prop-types"
import Heading from "../../atoms/Heading"
import DiagonalArrowIcon from "../../../images/icons/diagonal-arrow.svg"

const BenefitItem = ({ title, lead }) => {
  return (
    <div className="w-full sm:w-1/2 md:w-1/3 px-4">
      <div className="flex py-4 md:py-8">
        <div className="flex-shrink-0">
          <DiagonalArrowIcon
            className="stroke-primary mr-2"
            width="26px"
            height="26px"
          />
        </div>
        <div>
          <Heading variant="h3" classes="mb-4 font-extrabold">
            {title}
          </Heading>
          <p className="mb-0 text-beige-160">{lead}</p>
        </div>
      </div>
    </div>
  )
}

BenefitItem.propTypes = {
  title: PropTypes.node.isRequired,
  lead: PropTypes.string.isRequired,
}

BenefitItem.defaultProps = {
  title: ``,
  lead: ``,
}

export default BenefitItem
