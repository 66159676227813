import React from "react"
import PropTypes from "prop-types"

const SubHeader = ({ label }) => {
  return (
    <span className="inline-block text-base font-extrabold border-b-3 border-primary mb-4">
      {label}
    </span>
  )
}

SubHeader.propTypes = {
  label: PropTypes.string.isRequired,
}

SubHeader.defaultProps = {
  label: ``,
}

export default SubHeader
