import React from "react"
import PropTypes from "prop-types"
import Heading from "../../atoms/Heading"
import SubHeader from "../../atoms/SubHeader"
import BenefitItem from "../../molecules/BenefitItem"

const BenefitsSection = ({ header, subHeader }) => {
  return (
    <section className="py-8 md:py-20">
      <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
        <SubHeader label={subHeader} />
        <Heading variant="h2">{header}</Heading>
        <div className="flex flex-wrap -mx-4 mt-8">
          <BenefitItem
            title="Private health insurance"
            lead="Your health is important to us. Insurance provided by Allianz."
          />
          <BenefitItem
            title="Multisport Plus"
            lead="Membership to sport facilities all over Poland to keep us stay energized and fit."
          />
          <BenefitItem
            title="Free lunches"
            lead="Free, fresh and healthy lunches delivered daily to our office."
          />
          <BenefitItem
            title="Fresh fruits"
            lead="Stay energized all day with fresh fruits and refreshments in our office."
          />
          <BenefitItem
            title="Knowledge sharing"
            lead="We share our experience - internal project and team knowledge sharing sessions."
          />
          <BenefitItem
            title="Best hardware"
            lead="We want you to work on the best equipment."
          />
          <BenefitItem
            title="Growth budget"
            lead="Subsidized conference and trainings in every aspect of your growth."
          />
          <BenefitItem
            title="Team retreats"
            lead="We are individuals with diverse talents but frequent meetings raise our team spirit."
          />
          <BenefitItem
            title="Remote work"
            lead="Work from wherever you preferunless you deliver your tasks."
          />
          <BenefitItem
            title="Flexible working hours"
            lead="Work in accordance with your own rhythm as we are flexible about your work time."
          />
          <BenefitItem
            title="English conversations"
            lead="Improve your communication skills while speaking with an Native English Speaker."
          />
          <BenefitItem
            title="Familiar atmosphere"
            lead="Enjoy a family atmosphere and support in every dimension."
          />
        </div>
      </div>
    </section>
  )
}

BenefitsSection.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
}

BenefitsSection.defaultProps = {
  header: ``,
  subHeader: ``,
}

export default BenefitsSection
