import React, { useState } from "react"
import PropTypes from "prop-types"
import Heading from "../../atoms/Heading"
import SubHeader from "../../atoms/SubHeader"
import ArrowIcon from "../../../images/icons/arrow.svg"
import { StaticQuery, graphql } from "gatsby"

const JobsSection = ({ header, subHeader }) => {
  const [clicked, setClicked] = useState("0")

  const handleToggle = index => {
    if (clicked === index) {
      return setClicked("0")
    }
    setClicked(index)
  }

  return (
    <StaticQuery
      query={graphql`
        query FooterNavQuery {
          allJobsYaml {
            edges {
              node {
                id
                name
                desc
                lead
                label
                content {
                  header
                  list
                }
              }
            }
          }
        }
      `}
      render={data => (
        <section className="py-8 md:py-20 bg-beige">
          <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
            <SubHeader label={subHeader} />
            <Heading variant="h2">{header}</Heading>
            <div className="border-t border-dark mt-8">
              <div>
                {data.allJobsYaml.edges.map((job, index) => {
                  return (
                    <div
                      className={`py-6 md:py-8 border-b border-dark job-entry relative ${
                        clicked === index ? "job-item-is-open" : ""
                      }`}
                    >
                      <div className="flex flex-wrap -mx-4">
                        <div className="w-full md:w-2/5 px-4">
                          <div className="flex flex-col md:flex-row md:items-center mb-6">
                            <Heading variant="h3" classes="font-extrabold">
                              {job.node.name}
                            </Heading>
                            {job.node.label !== "" && (
                              <div className="mt-2 md:mt-0 mb-2 md:mb-0">
                                <span className="inline-block bg-dark text-beige font-extrabold text-base px-4 py-1 md:ml-2">
                                  {job.node.label}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="lg:pr-24 text-beige-160">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: job.node.lead,
                              }}
                            />
                          </div>
                        </div>
                        <div className="w-full md:w-3/5 px-4">
                          <div className="lg:pr-28 job-item-content">
                            {job.node.desc.map((paragraph, index) => {
                              return <p key={index}>{paragraph}</p>
                            })}

                            {job.node.content.map((section, index) => {
                              return (
                                <div key={index}>
                                  <h4>{section.header}</h4>
                                  <ul>
                                    {section.list.map((listItem, index) => {
                                      return <li key={index}>{listItem}</li>
                                    })}
                                  </ul>
                                </div>
                              )
                            })}
                            <div className="mt-8">
                              <a
                                className="transition-all duration-300 ease-in-out inline-flex items-center justify-center font-extrabold py-3 px-6 text-base w-full xs:w-auto bg-primary hover:bg-primary-120 text-white hover:text-white focus:outline-none"
                                href="mailto:careers@peak11.com"
                              >
                                Apply now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        className="absolute top-8 right-0"
                        onClick={() => handleToggle(index)}
                      >
                        <ArrowIcon
                          width="32px"
                          height="32px"
                          className={`transform-gpu transition-all duration-300 ease-in-out ${
                            clicked === index ? "rotate-180" : "rotate-0"
                          }`}
                        />
                      </button>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
      )}
    />
  )
}

JobsSection.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
}

JobsSection.defaultProps = {
  header: ``,
  subHeader: ``,
}

export default JobsSection
